.generated-letter {
    @media (min-width: 768px) {
        margin: 0 !important;
    }

    .container.bg-white {
        & > *:not(.letter-bottom) {

            @media (min-width: 768px) {
                // padding: 1.5rem 1.5rem !important;
            }

        }

        @media (min-width: 768px) {
            max-width: 90.42% !important;
            width: 92.42%;
            margin: 1.75rem auto;
            padding: 2.188rem 2.188rem !important;
        }
       
    }

    ul.letter-button-container {
        li {

            

            @media (min-width: 768px) {
                margin-left: 1rem;
                margin-bottom: 0.5rem;
            }

            button {
                color: $black;
                &:hover {
                    background-color: $green;
                    color: $black;
                }

            }
        }
    }

    .letter-header {

        p {
            line-height: 1.5rem;
            
        }

       
       
    }

    .letterEditor-container {
        background-color: $offWhite;
        width: 100% !important;
        border: 0.063rem solid $actionBorder;
        margin-top: 1.5rem !important;

        @media (min-width: 768px) {
            padding: 2.75rem 0;

        }

        #letterEditor-viewer {
            max-width: 100% !important;
            margin-bottom: 2rem;
            padding: 1rem 1.5rem !important;
            

            @media (min-width: 768px) {
                margin: 0 auto !important;
                width: 70% !important;
            }

            h3 {
                font-size: 1rem;
                line-height: 1.8rem;

                @media (min-width: 768px) {
                    font-size: 1.188rem;
                }
            }

            p {
                font-size: 1rem;
                line-height: 1.8rem;

                @media (min-width: 768px) {
                    font-size: 1.188rem;
                }
            }

        }
    }

    .letter-bottom {
        
        padding-top: 2rem;

        @media (max-width: 767px) {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        @media (min-width: 768px) {
            margin-top: 1.875rem;
        }

        ul {
            margin-bottom: 1.875rem;
        }

        .button {
            padding-left: 1.2rem;
            padding-right: 1.2rem;

            &:first-of-type {
                text-align: center;
            }
        }
    }

    .action-buttons {
        margin-top: 2rem;
    }

    .action-back {
        border: 0.125rem solid $green;
        width: 15.563rem;
        height: 3.688rem;
        position: relative;
        text-align: left;
        font-size: 1.25rem;
        color: $black;

        &:hover {
            background-color: $green;
            color: $black;
        }

        &::after {
            content: "";
            width: 2.082rem;
            height: 0.801rem;
            display: inline-block;
            background: url("../../images/svg/arrow-right.svg") no-repeat center/100%;
            position: absolute;
            top: 50%;
            right: 2rem;
            transform: translateY(-50%);
        }
    }

    .back {
        border: 0.125rem solid $borderLight;
        width: 3.563rem;
        height: 3.688rem;
        position: relative;
        text-align: left;
        font-size: 1.25rem;
        color: $black;
        text-indent: -9999999px;
        background-color: $white !important;

        @media (min-width: 768px) {
            text-indent: 0;
            width: 7.563rem;
        }

        &::after {
            content: "";
            width: 2.082rem;
            height: 0.801rem;
            display: inline-block;
            background: url("../../images/svg/arrow-right.svg") no-repeat center/100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(180deg);

            @media (min-width: 768px) {
                display: none;
            }
        }
    }

    .homelessness {
        .container {
            max-width: 95.42% !important;
        }
    }


    strong {
        font-family: 'Helvetica' !important;

        @media (min-width: 768px) {
            font-size: 1.188rem;
        }
    }
}