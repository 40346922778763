.chart {
	padding: 2.3rem 0 2rem;

	@media (min-width: 768px) {
		padding: 3.125rem 0 3.625rem;
	}

	.container {
		max-width: 100% !important;
		width: 100%;
		margin: 0;
		padding: 0;

		@media (min-width: 768px) {
			width: 92.4%;
			max-width: 92.4%;
			margin: 0 auto;
		}

		.title,
		.title + div {
			margin-left: 0 !important;
		}

		& > *:not(.chart-mobile-container) {
			max-width: 92.42%;
			width: 92.42%;
			margin-left: auto;
			margin-right: auto;
			padding-left: 0.8rem;
			padding-right: 0.8rem;

			@media (min-width: 768px) {
				width: 100%;
				max-width: 1200px;
			}

			&.content-container {
				margin-top: 2rem;

				@media (min-width: 768px) {
					max-width: 70%;
					width: 70%;
					margin-left: 0;
				}

				@media (min-width: 992px) {
					max-width: 65%;
					width: 65%;
					margin-left: 0;
				}

				h3 {
					@media (min-width: 768px) {
						font-size: 2.5rem;
						line-height: 2.3rem;
						margin-bottom: 1rem;
					}
				}

				p.description {
					@media (min-width: 768px) {
						margin-bottom: 2rem;
					}
				}
			}
		}
	}

	h3 {
		margin-bottom: 1rem;
	}

	.chart-image {
		width: auto !important;
		margin-top: 1.563rem !important;

		@media (min-width: 768px) {
			padding: 1.563rem 0 1.063rem;
			// max-height: 17.313rem;
			width: 80% !important;
			margin-left: 0 !important;
			padding-top: 0 !important;
		}
	}

	.chart-mobile-container {
		height: auto;
		width: auto !important;
		overflow-x: auto;
		padding-left: 1.5rem;
		padding-right: 1.5rem;

		&::-webkit-scrollbar {
			width: none;
			display: none;
		}

		img {
			width: auto;
			height: auto;
			object-fit: cover;
		}
	}

	// .description {
	//     width: 50%;
	// }
}
