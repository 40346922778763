.action-card-container {

    margin-top: 1.75rem;

    .info {
        margin-bottom: 0;
    }

    .action-cards {
        padding: 1.5rem 1rem;
        border: 0.125rem solid $borderLight;
        border-radius: 0.313rem;

        &.greenBorder {
            border-color: $green;
        }
        
        @media (min-width: 768px) {
        padding: 2.563rem 2.813rem;

        }
        h3 {
            margin-bottom: 0.563rem;
        }

        h4 {
            font-weight: 700;
            padding-top: 1.5rem;
            border-top: 0.125rem solid $borderLight;
        }

        p {
            margin-bottom: 1.5em !important;
        }
        ul {
            list-style-type: initial !important;
        }
        .button-option {
            font-size: 1.063rem;
            font-weight: 400;
            line-height: 1.286rem;
            color: #BBBBBB;
            margin: 0 1.75rem !important;
        }

        .button {

            color: $black;
            font-size: 1.063rem;
            font-weight: 600;
            padding: 0.75rem 1.5rem;

            @media (max-width: 767px) {
                width: 100%;
            }

            &:not(:last-child) {
                margin-bottom: 1rem;

                @media (min-width: 768px) {
                    margin-bottom: 0;
                }
            }
        }
    }

    .action-plan-external {
        margin-top: 2.5rem;
        flex-wrap: wrap;
        li {
            margin-top: 0 !important;
            margin-bottom: 1.5rem;
            
            h4 {
                padding-top: 0;
                border-top: 0;
            }
        }
    }
}