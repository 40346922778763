.action-plan {

    .container-wrap {

        @media (max-width: 992px) {
            width: 100% !important;
            max-width: 100% !important;
            padding: 0; 
            margin-top: 0;
        }
    }

    .action-description {
        margin-top: 1rem;
        @media (min-width: 768px) {
            margin-top: 1.563rem;
        }
    }
    

    & > div {
        margin: 1.75rem auto;

        .action-plan-container {
            padding: 1.5rem 1.031rem;
            order: 2;
            flex-basis: 100%;

            @media (min-width: 992px) {
                order: 1;
                flex-basis: 71%;
                padding: 2.563rem 2rem;


            }

        }

        .accordion-list {
            position: relative;
            &::after {
                content: "";
                display: inline-block;
                width: 0.938rem;
                height: 0.6rem ;
                background: url("../../images/accordion-arrow.svg") no-repeat center/100%;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .action-accordion {
            flex-basis: 100%;
            background-color: $white;
            padding: 1rem;
            order: 1;
            margin-top: 0;

            @media (min-width: 992px) {
                order: 2;
                flex-basis: 28%;
                background-color: transparent;
                padding: 1rem;
                padding-top: 0;

            }

            .question-title {
                margin-top: 0;
            }

            .accordion-item {
                border: none !important;
                border-bottom: 0.125rem solid $borderLight !important;
                margin-bottom: 0;

                button {
                    padding: 1rem;
                    font-weight: 400;
                    font-family: 'inter';
                    font-size: 1.125rem;
                    line-height: 1.361rem;

                    &::after {
                        margin-left: auto;
                    }
                }

            }

            .accordion-body {
                padding: 1rem;
                padding-top: 0;
                font-weight: 700;
            }

        }
    }

    .question-error {
        padding: 0;
        margin-top: 1.75rem;

        .accordion-item {
            border-radius: 0.313rem;

            p {
                margin-bottom: 0;
            }

            @media (min-width: 768px) {
                border-radius: 0.188rem;
            }

            ul {
                list-style-type: disc;
                padding-left: 1rem;
                
                li {
                    margin-top: 1rem;
                }
                
            }
        }
    }

    .recommended {
        margin-top: 1.75rem;

        h4 {
            margin-right: 0rem;

            @media (min-width: 768px) {
                margin-right: 1.313rem;
            }

        }
    }

    .case-list-container {
        flex-wrap: wrap;
        justify-content: space-between;
        
        li {

            flex-basis: 100% !important;
            margin-right: 0;

            @media (min-width: 768px) {
                flex-basis: 48% !important;
            }

            h4 {
                color: $_darkGrey !important;
            }
        }
    }

    .general-data-container {
        @media (max-width: 992px) {
            width: 100%;
            max-width: 100% !important;
            padding: 0rem 1.031rem;
        } 
    }

    .general-data {
        width: 100%;
        max-width: 100%;

        @media (min-width: 992px) {
            width: 71%;
            max-width: 71%;
        } 

        .container {
            width: 100% !important;
            max-width: 100% !important;
            padding: 0 !important;
        }
        
    }

    .action-info {
        font-size: 1.05rem;
        font-weight: 600;
        font-family: "helvetica";
        color: $darkBlue;
        margin-bottom: 1.25rem;


        &::before {
            content: "";
            width: 1.063rem;
            height: 1.063rem;
            display: inline-block;
            background: url("../../images/blue-info.svg") no-repeat center/100%;
            margin-right: 0.5rem;
            vertical-align: middle;
        }
    }

    .edit {
        font-family: 'inter';
        font-size: 1.125rem;
        line-height: 1.361rem;
        font-weight: 600;
        border: none;
        background-color: transparent;
        align-self: flex-end;
        color: $darkBlue;
        border-bottom: 0.188rem solid $green;
        padding: 0;
        float: right;
        margin-top: 1.063rem;
    }

    .step-dropdown {
        position: relative;

        margin-left: 1.5rem;

        @media (min-width: 768px) {
            margin-left: 0;
        }


        &::before {
            content: "";
            width: 1.146rem;
            height: 0.729rem;
            display: inline-block;
            background: url('../../images/filter-icon.svg') no-repeat center/100%;
            position: absolute;
            left: -1.5rem;
            top: 45%;
            transform: translateY(-60%);
        }
    }

    .action-prev {
        background-color: transparent;
        border: 0.125rem solid $borderLight;
        width: 5.313rem;
        height: 3.688rem;
        position: relative;
        &::after {
            content: "";
            width: 2.082rem;
            height: 0.801rem;
            display: inline-block;
            background: url("../../images/svg/arrow-left.svg") no-repeat center/100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .action-next {
        border: 0.125rem solid $green;
        width: 12.563rem;
        height: 3.688rem;
        position: relative;
        text-align: left;
        font-size: 1.25rem;
        color: $black;
        &::after {
            content: "";
            width: 2.082rem;
            height: 0.801rem;
            display: inline-block;
            background: url("../../images/svg/arrow-right.svg") no-repeat center/100%;
            position: absolute;
            top: 50%;
            right: 2rem;
            transform: translateY(-50%);
        }
    }

    .other-resource {
        font-family: "Helvetica";
        font-size: 1.688rem;
        color: $black;
        line-height: 1.941rem;
        font-weight: 700;
        margin: 2rem 0 2.188rem;
        @media (min-width: 768px) {
            margin: 2.5rem 0 2.188rem;

        }
    }

    .homelessness {
        padding: 0 !important;

        h3 {
            margin-bottom: 0.5rem;
        }

        p {
            margin-bottom: 1.875rem;
        }

        ul {
            li {
                @media (min-width: 768px) {
                    margin-top: 0 !important;
                }
            }
        }

        .card-container {
            margin-top: 0 !important;
        }
    }

    .card-container {
        margin-top: 3rem;

        &.topics-card {

            margin-bottom: 4.5rem;

            @media (min-width: 768px) {
                margin-bottom: 7rem;

            }
        }

        & > p {
            margin-bottom: 0.3rem;
        }
    }

    .bottom-buttons {
        .button {
            flex-basis: 48%;
            font-size: 1.125rem;
            line-height: 1.294rem;
            font-weight: 700;
            font-family: "Helvetica";
            background-color: $white;
            border: 0.065rem solid $green;
            border-radius: 0.125rem;
            padding: 1rem 0;
            text-align: center;
            color: $black;
        }
    }

    .topics-card {
        ul {
            justify-content: space-between;

            @media (min-width: 768px) {
                justify-content: flex-start;
            }
        }
    }
}