@import "variables";
.accordion-container {

    background-color: $white;

    .accordion-item {

        margin-bottom: 1.5rem;
        border: 0.188rem solid $borderLight;
        // border-radius: 1.25rem;
        overflow: auto;

        @media (min-width: 768px) {
            border-radius: 0.313rem;
        }

        &:has(> div.show) {
            border: 0.188rem solid $green;
        }

        p{
            margin-bottom: 0rem !important;
        }

        button {

            font-size: 1rem !important;
            font-weight: 700;
            line-height: 1.5rem;
            color: $_darkGrey;
            background-color: $white !important;
            box-shadow: none !important;
            border: none;

            @media (min-width: 768px) {
                padding: 1.5rem;
                font-size: 1rem !important;
                line-height: 1.25rem;
            }
            
            &::after {
                
                width: 1.092rem;
                height: 0.707rem;
                background: url('../../images/accordion-arrow.svg') no-repeat center/cover;
                
                // @media (max-width: 767px) {
                //     margin-left: 0.5rem;
                // }
            }

        }


    
        .accordion-body {
            padding-top: 0;
            background-color: $white;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            font-family: 'helvetica';
            color: $_darkGrey;
            padding-bottom: 0;
        }
     .accordion-edit{
        font-family: "inter";
        font-size: 1rem !important;
        line-height: 1.125rem;
        font-weight: 600;
        border: none;
        align-self: flex-end;
        background-color: transparent;
        color: #003078;
        border-bottom: 0.188rem solid #00DD97;
        padding: 0 !important;
        float: right;
        margin-top: 0rem;
        margin-left: 1rem !important;
    }   
    }
    
}